import { graphql } from "gatsby";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import queryString from "query-string";
import { Trans, useTranslation, useI18next } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import { accountVerifyRequest, regionRequest } from "../../actions";
import moment from "moment";
import LayoutFloating from "../../components/LayoutFloating";

const VerifyUser = (props) => {
  const { t } = useTranslation();
  const { language } = useI18next();
  const {
    region_data: { unique_region_text, active_language },
    region_loading,
    dispatch,
  } = props;
  const params =
    props.location &&
    props.location.search &&
    queryString.parse(props.location.search);

  const { success } = props.account_verify_response || {};

  if (params && props.account_verify_request_time == null) {
    // Hardcoding params so user can't try arbitrary fields
    const data = {
      user_id: params.user_id,
      timestamp: params.timestamp,
      signature: params.signature,
    };

    dispatch(accountVerifyRequest(data));
  }

  useEffect(() => {
    if (
      (!region_loading && !unique_region_text) ||
      language !== active_language
    ) {
      dispatch(regionRequest(language));
    }
  }, [unique_region_text, region_loading, language, dispatch, active_language]);

  return (
    <Layout
      pageName="account-login"
      showHeader={false}
      siteTitle={unique_region_text?.siteTitle ?? ""}
      siteDescription={unique_region_text?.siteDescription ?? ""}
    >
      <Helmet>
        <title>{unique_region_text?.siteTitle ?? ""}</title>
      </Helmet>
      <LayoutFloating>
        <div className="form-signin-groups">
          <h2 className="py-0 mt-0">
            {success ? t("Verified") : t("Verifying...")}
          </h2>
          <p className="pb-2 my-0">
            <small className="text-muted font-italic">
              {props.account_verify_request_time
                ? moment(props.account_verify_request_time).format(
                    "dddd, MMMM Do YYYY, h:mm a"
                  )
                : null}
            </small>
          </p>
          {success ? (
            <p className="alert alert-secondary">
              <Trans>
                Your account has been verified. Please log in to continue.
              </Trans>
            </p>
          ) : null}
          <div className="account-container-btn">
            <Button variant="primary" size="lg" href="/account/login" block>
              <Trans>Log In</Trans>
            </Button>
          </div>
        </div>
      </LayoutFloating>
    </Layout>
  );
};

export default connect((state) => state)(VerifyUser);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
