import { Link } from "gatsby";
import React from "react";
import NavLogo from "./NavLogo";

const renderRightLink = (href, icon, hide) => {
  if (icon && !hide) {
    return (
      <span className="link-wrap float-right">
        <Link to={href}>{icon}</Link>
      </span>
    );
  }
  return;
};

export const HeaderSmall = ({
  href = "/",
  logo = <img />,
  title = "",
  rightHref = "/",
  rightIcon = null,
  hideRightLink = false,
  appendClassName = "",
}) => (
  <header className={`small-header ${appendClassName}`}>
    <NavLogo appLogo={logo} logoLinkPath={href} logoAltText={title}>
      <span className="nav-link header-text-link text-light">{title}</span>
    </NavLogo>
    {renderRightLink(rightHref, rightIcon, hideRightLink)}
  </header>
);
